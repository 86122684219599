/**
 * Represents the type of the community
 */
export enum CommunityType {
  SCHOOL = 'school',
  LEA = 'lea',
}

/**
 * Community Input Props
 */

/**
 * Community Output Props
 */
export interface EnrolledCommunityOutputProps {
  communityId: string
  communityName: string
  country: string
  adminArea: string
  subAdminArea?: string
  locality: string
  subLocality?: string
  postalCode: string
}
