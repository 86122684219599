import React from 'react'
import AvatarComponent from 'components/avatar'
import dayjs from 'dayjs'

export type MsgCardComponentProps = {
  text: string
  files?: string[]
  sender: string
  sentAt: string
  photoLink?: string
  failedPhotoLinks: Set<string>
  onFailedPhotoLink: (url: string) => void
  isMyMsg?: boolean
  isPrivateChat?: boolean
  isEdited?: boolean
}

const MsgCardComponent: React.FC<MsgCardComponentProps> = ({
  text,
  sender,
  sentAt,
  photoLink,
  failedPhotoLinks,
  onFailedPhotoLink,
  isMyMsg = false,
  isPrivateChat = false,
  isEdited = false,
  files,
}) => {
  const formattedTime = dayjs(sentAt).format('MMM D, h:mm A') // Example format: "Oct 15, 2:45 PM"

  // Convert new line characters to <br /> elements
  const formattedText = text.split('\n').map((line, index) => (
    <React.Fragment key={index}>
      {line}
      <br />
    </React.Fragment>
  ))

  return (
    <div className={`flex ${isMyMsg ? 'flex-row-reverse items-end' : 'items-start'} mb-4`} style={{ gap: '16px' }}>
      {/* Avatar */}
      {!isMyMsg && !isPrivateChat && (
        <div className='flex flex-col items-start'>
          <AvatarComponent
            size={8}
            name={sender}
            photoLink={photoLink}
            failedPhotoLinks={failedPhotoLinks}
            onFailedPhotoLink={onFailedPhotoLink}
          ></AvatarComponent>
          <p className='text-xs mt-1' style={{ color: '#272D37', textAlign: 'left' }}>
            {sender}
          </p>
        </div>
      )}
      <div className='flex flex-col'>
        {/* Card */}
        <div
          className={`rounded-md ${
            isMyMsg ? '' : 'drop-shadow-md'
          } ${isMyMsg ? 'text-[#5A5A5A]' : 'text-[#272D37]'} w-[70vw] min-w-[305px] max-w-full`}
          style={{
            backgroundColor: isMyMsg ? '#F0E977' : '#FFFFFF',
            color: isMyMsg ? '#5A5A5A' : '#272D37',
            padding: '12px 16px',
            fontSize: '14px',
          }}
        >
          <p>
            {formattedText} {isEdited && <span className='text-sm opacity-60'>(edited)</span>}
          </p>
          {files && files.length > 0 && (
            <div className='mt-2 flex flex-wrap gap-2'>
              {files.map((file, index) => (
                <a key={index} href={file} target='_blank' rel='noopener noreferrer' className='underline text-sm'>
                  File {index + 1}
                </a>
              ))}
            </div>
          )}
        </div>
        {/* Time */}
        <p
          className='mt-1 text-xs'
          style={{
            fontSize: '10px',
            color: '#7C8B9D',
            textAlign: isMyMsg ? 'right' : 'left', // Right-align for sent messages
          }}
        >
          {formattedTime}
        </p>
      </div>
    </div>
  )
}

export default MsgCardComponent
