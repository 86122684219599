import Box from '@mui/material/Box'
import MetricsComponent from 'components/metrics'
import TextComponent from 'components/text'
import { SchoolStatProps } from 'types/SchoolTypes'

type SchoolDashboardProps = {
  schoolStats?: SchoolStatProps
}

function Index(props: SchoolDashboardProps): JSX.Element {
  const { schoolStats } = props
  return (
    <Box px={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }} py={{ xs: 1, sm: 2, md: 2, lg: 3, xl: 3 }}>
      {/***************** Show school name in Title *****************/}
      <TextComponent size='h5' fontWeight='bold' align='left' mb='16px'>
        Analytics
      </TextComponent>

      {/***************** Show analytics in Charts *****************/}
      <MetricsComponent schoolStats={schoolStats} />
    </Box>
  )
}

export default Index
