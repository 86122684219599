import AvatarComponent from 'components/avatar'
import BackComponent from 'components/back'
import ButtonMobileComponent from 'components/button-mobile'
import HorizontalScrollContainerComponent from 'components/horizontal-scroll-container'
import {
  ClearIcon,
  CreateGroupIcon,
  JoinGroupIcon,
  PrivateGroupIcon,
  PublicGroupIcon,
  SearchIcon,
} from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import WizardComponent from 'components/wizard'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RoomType, UserRoomsOutputProps } from 'types/RoomTypes'
import { isWeb, titleCaseSchoolNameForUI } from 'utils/constants'

// Mock Data Types
interface RoomsComponentProps {
  rooms: UserRoomsOutputProps[] // all rooms
  failedPhotoLinks: Set<string>
  onFailedPhotoLink: (url: string) => void
  communityIds: string[] // all communities
}

interface CardComponentProps {
  id: string
  avatar: JSX.Element
  title: string
  subtitle?: string
  body: string
  date: Date
  count: number
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, subtitle, body, date, count } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-center self-stretch'>
        <div className='flex items-center gap-3 flex-1'>
          {avatar}
          <div className='flex flex-col'>
            <div className='flex items-center gap-1 max-w-[60vw]'>
              <span className='max-w-[40vw] w-auto text-[#474747] text-sm font-medium overflow-hidden whitespace-nowrap text-ellipsis'>
                {title}
              </span>
              {subtitle && (
                <>
                  <span className='w-[1px] bg-gray-300 h-[20px]' />
                  {/* <div className='text-[12px] bg-light-yellow py-1 px-2 rounded-xl'>{subtitle}</div> */}
                  <span className='text-[#797979] text-xs font-medium overflow-hidden whitespace-nowrap text-ellipsis'>
                    {subtitle}
                  </span>
                </>
              )}
            </div>
            <span className='text-[#797979] text-xs font-regular max-w-[60vw] overflow-hidden whitespace-nowrap text-ellipsis'>
              {body}
            </span>
          </div>
        </div>
        <div className='flex flex-col items-end text-[#797979] text-xs font-regular ml-auto'>
          <div>
            {date.toDateString() === new Date().toDateString()
              ? date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
              : date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' })}
          </div>
          <div className='bg-custom-yellow rounded-xl px-1'>
            {/* Backend always retrieves the latest message */}
            {count >= 10 ? '10+' : count > 1 ? (count - 1).toString() : ''}
          </div>
        </div>
      </div>
    </div>
  )
}

const RoomsComponent: React.FC<RoomsComponentProps> = ({
  rooms,
  failedPhotoLinks,
  onFailedPhotoLink,
  communityIds,
}) => {
  const filters: string[] = ['All', 'Private chats', 'Private groups', 'Public groups']
  const isShowCommunityName: boolean = communityIds.length > 1
  const isDisableButtons: boolean = communityIds.length === 0

  // State for selected school and room type filter
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [selectedFilter, setSelectedFilter] = useState<string>(filters[0])
  const [filteredRooms, setFilteredRooms] = useState<CardComponentProps[]>([])

  // hooks
  useEffect(() => {
    const cards: CardComponentProps[] = []
    rooms.forEach((v: UserRoomsOutputProps) => {
      const {
        roomType,
        roomId,
        roomName,
        roomDescription,
        roomPhotoLink,
        roomCommunityId,
        roomCommunityActivity,
        roomUpdatedAt,
        userIsMember,
        roomLastMsgs,
      } = v
      const isKeywordFound: boolean =
        !searchKeyword ||
        [roomName, roomDescription, roomCommunityId, roomCommunityActivity].some(
          (field) => field && field.toLowerCase().includes(searchKeyword.toLowerCase()),
        )
      const isRoomTypeFound: boolean =
        selectedFilter === 'All' ||
        (selectedFilter === 'Private chats' && roomType === RoomType.PRIVATE) ||
        (selectedFilter === 'Private groups' && roomType === RoomType.GROUP) ||
        (selectedFilter === 'Public groups' && (roomType === RoomType.PUBLIC || roomType === RoomType.ACTIVITY))
      if (userIsMember && isKeywordFound && isRoomTypeFound) {
        let body: string, date: Date, count: number
        if (roomLastMsgs?.length) {
          const { msgText, msgSentBy, msgSentAt } = roomLastMsgs[0]
          body = roomType === RoomType.PRIVATE ? msgText : `${msgSentBy}: ${msgText}`
          date = new Date(msgSentAt)
          count = roomLastMsgs.length
        } else {
          body = roomDescription ?? 'No messages'
          date = new Date(roomUpdatedAt)
          count = 0
        }
        cards.push({
          id: roomId,
          avatar:
            roomType === RoomType.PRIVATE ? (
              <AvatarComponent
                name={roomName}
                photoLink={roomPhotoLink}
                failedPhotoLinks={failedPhotoLinks}
                onFailedPhotoLink={onFailedPhotoLink}
              />
            ) : roomType === RoomType.GROUP ? (
              <PrivateGroupIcon width={40} height={40} />
            ) : (
              <PublicGroupIcon width={40} height={40} />
            ),
          title: roomName,
          subtitle: isShowCommunityName && roomCommunityId ? titleCaseSchoolNameForUI(roomCommunityId) : undefined,
          body,
          date,
          count,
        })
      }
    })
    setFilteredRooms(cards.sort((a, b) => b.date.getTime() - a.date.getTime()))
  }, [isShowCommunityName, rooms, failedPhotoLinks, onFailedPhotoLink, searchKeyword, selectedFilter])

  // body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px]'}`}>
      {/***************** Search Bar *****************/}
      <InputMobileComponent
        type='text'
        placeholder='Search your groups...'
        value={searchKeyword ?? ''}
        onChange={(v: string) => setSearchKeyword(v)}
        rightElement={searchKeyword ? <ClearIcon onClick={() => setSearchKeyword('')} /> : <SearchIcon />}
      />

      {/***************** Room Type Selector *****************/}
      {filters.length > 2 && (
        <HorizontalScrollContainerComponent className='my-2'>
          {filters.map((v: string, i: number) => (
            <span
              key={i}
              onClick={() => setSelectedFilter(v)}
              className={`text-xs rounded-xl px-2 py-1 cursor-pointer ${
                selectedFilter === v
                  ? 'bg-custom-yellow text-gray-800'
                  : 'bg-light-yellow border-custom-yellow border text-gray-800'
              }`}
            >
              {v}
            </span>
          ))}
        </HorizontalScrollContainerComponent>
      )}

      {/* **************** Room List **************** */}
      {filteredRooms.map((room: CardComponentProps, index: number) => (
        <Link to={`/messaging/${room.id}`} key={index}>
          <CardComponent
            id={room.id}
            avatar={room.avatar}
            title={room.title}
            subtitle={room.subtitle}
            body={room.body}
            date={room.date}
            count={room.count}
          />
        </Link>
      ))}
    </div>
  )
  // footer
  const footer: JSX.Element = (
    <div className='flex justify-around gap-3'>
      <ButtonMobileComponent
        icon={<CreateGroupIcon />}
        href={`/messaging?action=createRoom`}
        text={'Create group'}
        disabled={isDisableButtons}
        // className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center'
      />
      <ButtonMobileComponent
        icon={<JoinGroupIcon />}
        href={`/messaging?action=joinRoom`}
        text={'Join group'}
        disabled={isDisableButtons}
        // className='w-full px-4 py-1.5 bg-custom-yellow text-headings rounded-lg flex items-center justify-center'
      />
    </div>
  )

  // Filtered Rooms
  return (
    <>
      {/***************** Mobile *****************/}
      {!isWeb && (
        <BackComponent text='Messaging (Beta)'>
          {body}
          <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
            <div className='border-b border-gray-200'></div>
            <div className='pt-4 pb-7 px-5'>{footer}</div>
          </div>
        </BackComponent>
      )}
      {/***************** Web *****************/}
      {isWeb && (
        <WizardComponent title='Messaging (Beta)'>
          <>
            {body}
            {footer}
          </>
        </WizardComponent>
      )}
    </>
  )
}

export default RoomsComponent
