import CircleLoader from 'components/Circularloader'
import { ReactNode, useState } from 'react'
import { Link } from 'react-router-dom'

interface ButtonMobileComponentProps {
  icon?: string | ReactNode
  id?: string
  color?: string
  textColor?: string
  disabled?: boolean
  href?: string
  onClick?: () => Promise<void> | void
  text: string
  padx?: string
  width?: string
  height?: string
  data_testid?: string
  iconPosition?: 'left' | 'right'
  className?: string
}

const ButtonMobileComponent = (props: ButtonMobileComponentProps) => {
  const {
    icon,
    id,
    color = 'bg-custom-yellow',
    textColor,
    disabled,
    href,
    onClick,
    text,
    padx = 'px-[16px]',
    width = 'w-full',
    height = 'h-[44px]',
    data_testid,
    iconPosition = 'left',
    className = '',
    ...rest
  } = props
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Disable button if disabled prop or isLoading state is true
  const isButtonDisabled = disabled || isLoading

  // Common button styles with dynamic classes
  const buttonBaseStyles = `rounded-lg flex justify-center items-center gap-[6px] ${height} ${padx} ${width} ${color} ${isButtonDisabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'}`
  const combinedButtonStyles = `${buttonBaseStyles} ${className}`

  // If the icon is a URL, you can directly use it in the `src` attribute of an <img> tag
  const renderIcon = icon ? typeof icon === 'string' ? <img src={icon} alt='icon' /> : icon : null

  // Button content with text and optional icon
  const buttonContent = (
    <>
      {iconPosition === 'left' && renderIcon}
      <div
        className={`text-headings text-md font-medium leading-normal ${isButtonDisabled ? 'text-dis' : ''} ${textColor}`}
      >
        {text}
      </div>
      {iconPosition === 'right' && renderIcon}
    </>
  )

  // If href is provided, render a Link component
  if (href) {
    return (
      <Link id={id} to={disabled ? '#' : href} className={combinedButtonStyles} data-testid={data_testid} {...rest}>
        {buttonContent}
      </Link>
    )
  }

  // Otherwise, render a button element
  return (
    <button
      id={id}
      className={combinedButtonStyles}
      disabled={isButtonDisabled}
      data-testid={data_testid}
      onClick={async () => {
        setIsLoading(true)
        if (onClick) await onClick()
        setIsLoading(false)
      }}
      {...rest}
    >
      {isLoading ? <CircleLoader /> : buttonContent}
    </button>
  )
}

export default ButtonMobileComponent
