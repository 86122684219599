import ButtonMobileComponent from 'components/button-mobile'
import AddCarpool from '../../images/Purchase_Orders.svg'
import React from 'react'
import './index.css'
import { PlusIcon } from 'components/icons'

interface AddressAddComponentProps {
  isAMPool: boolean
  href: string
}

const AddressAddComponent: React.FC<AddressAddComponentProps> = ({ isAMPool, href }) => {
  return (
    <div className='drawerheight' data-testid='drawerheight-testid'>
      <div className='flex flex-col items-center justify-center flex-grow'>
        <img src={AddCarpool} alt='' />
        <p className='text-center text-disable text-md mt-6 font-semibold text-headings'>
          Needs {!isAMPool ? 'drop off location' : 'pick up location'}
        </p>
      </div>

      <div className='w-full lg:w-44 lg:mx-auto lg:mb-10'>
        <ButtonMobileComponent
          data_testid='carpool-testid'
          id='find-school-button'
          text={!isAMPool ? 'Afternoon Carpool' : 'Morning Carpool'}
          disabled={false}
          color='bg-custom-yellow'
          width='w-full'
          href={href}
          iconPosition='left'
          icon={<PlusIcon />}
        />
      </div>
    </div>
  )
}

export default AddressAddComponent
