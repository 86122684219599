import { GetSchoolUsersOutputProps, SchoolStatProps } from 'types/SchoolTypes'

/**
 * LOCATION PROPS
 */
export type PositionProps = {
  lat: number
  lng: number
}

export type LocationProps = {
  address: string
  lat?: number
  lng?: number
  placeId?: string
}

/**
 * SCHEDULE PROPS
 */
export type DateTimeProps = {
  [date: string]: number
}

export type ScheduleGroupProps = {
  schoolScheduleGroupName: string
  schoolScheduleGroupStartTime: number
  schoolScheduleGroupEndTime: number
}

/**
 * BILLING PROPS
 */
export type PaymentProps = {
  currentPeriodStart: number
  currentPeriodEnd: number
  paymentType: string
  cardLast4: string
  cardBrand: string
  cardExpMonth: string
  cardExpYear: string
}
export type SubscriptionProps = {
  maxRegistrations?: number
  billingCycle?: string
}
export type GetSubscriptionProps = SubscriptionProps & { paymentMethod?: PaymentProps }
export type UpdateSubscriptionProps = SubscriptionProps & { isGetClientSecret?: boolean; paymentMethodId?: string }

// Vehicle Interfaces
export enum VehicleProps {
  NONE = 0,
  HIRE_TRANSPORT_SERVICE = 1,
}

// React Bootstrap Interfaces
export interface MessageProps {
  style?: string
  heading?: string
  text?: string
  duration?: number
}

export interface TabProps {
  key: string
  title: JSX.Element | string
  children: JSX.Element
}

export interface iconSizeProps {
  width: number
  height: number
}

/**
 * MAP PROPS
 */
export interface MarkerProps {
  position: PositionProps
  iconPath?: string
  iconFillColor?: string
  label?: string
  infoWindowId?: string
  infoWindowContent?: string
  circleRadius?: number
  iconSize?: iconSizeProps
  draggable?: boolean
  zIndex?: any
}

/**
 * CALENDAR PROPS
 */
export enum CalendarState {
  DISABLE = 'disabled',
  ENABLE = 'enabled',
}

export type CalendarEventProps = {
  from: Date
  eventState?: CalendarState
}

export type CalendarProps = {
  from: Date
  userAction: CalendarState
}

/**
 * API PROPS
 */
export interface ResponseProps<T = Record<string, unknown>, E = Record<string, unknown>> {
  msg?: MessageProps
  data?: T
  error?: E
}

/**
 * FAMILY USER PROPS
 */
export type StudentProps = {
  studentId: string
  schoolName: string
  isCarpoolActive?: boolean
  isSchoolCode?: boolean
  isSchoolSubscription?: boolean
  schoolLocation: LocationProps
  schoolStats: SchoolStatProps
  schoolScheduleGroups: ScheduleGroupProps[]
  schoolActivities: string[]
  schoolPoolRadius?: number
  schoolAdminLink?: string
  userRelationship: string
  scheduleGroupName: string
  studentName?: string
  isStudentSubscription?: boolean
  pickupLocation?: LocationProps
  dropoffLocation?: LocationProps
  pickupTimes?: DateTimeProps
  dropoffTimes?: DateTimeProps
  dropoffTimeDefault?: number
  studentActivities: string[]
  pickupVehicle?: VehicleProps
  pickupSeatsFilled?: number
  pickupSeatsAvailable?: number
  pickupComments?: string
  dropoffVehicle?: VehicleProps
  dropoffSeatsFilled?: number
  dropoffSeatsAvailable?: number
  dropoffComments?: string
}

export type SchoolPoolStudentProps = {
  userName: string
  userPhoneNumber: string
  userRelationship: string
  userPhotoLink: string
  studentId: string
  studentName: string
  seatsFilled?: number
  seatsAvailable?: number
  comments?: string
  position?: PositionProps
  dropoffTimeDefault?: number
  dropoffTime: number
  studentActivities: string[]
  scheduleGroupName: string
}

export type SchoolPoolProps = {
  schoolName: string
  schoolLocation: LocationProps
  schoolActivities: string[]
  schoolScheduleGroups?: ScheduleGroupProps[]
  students: SchoolPoolStudentProps[]
}

/**
 * ADMIN USER PROPS
 */
export type SchoolUsersProps = GetSchoolUsersOutputProps & {
  schoolName: string
}

export type AddUserProps = {
  userName: string
  userPhoneNumber: string
  scheduleGroupName: string
}

export type UpdateStudentProps = AddUserProps & {
  userRelationship?: any
  pickupLocation?: any
  dropoffLocation?: any
}
