/**
 * Represents the type of the partition key for room records
 */
export enum RoomType {
  PRIVATE = 'private',
  GROUP = 'group',
  PUBLIC = 'public',
  ACTIVITY = 'activity',
}

/**
 * Room, Message and RoomUser Input Props
 */
export interface CreateRoomInputProps {
  roomType: RoomType
  roomUsers: string[]
  roomName?: string
  roomDescription?: string
  roomCommunityId?: string
}
export interface AdminCreateRoomInputProps {
  roomType: RoomType
  roomUsers: string[]
  roomName: string
  roomDescription?: string
  roomCommunityId: string
  roomCommunityActivity: string
}
export interface UpdateRoomInputProps {
  roomType?: RoomType
  roomName?: string
  roomDescription?: string
  roomAdmins?: string[]
}
export interface AddRoomUsersInputProps {
  roomUsers: string[]
}
export interface RemoveRoomUsersInputProps {
  roomUsers: string[]
}
export interface UpdateRoomUserInputProps {
  userLastSeen?: string
}
/**
 * Room and RoomUser Output Props
 */
export interface RoomOutputProps {
  roomType: RoomType
  roomId: string
  roomName: string
  roomDescription?: string
  roomCreatedBy: string
  roomAdmins: string[]
  roomMemberCount: number
  roomCommunityId?: string
  roomCommunityActivity?: string
  createdAt: string
  updatedAt: string
}
export interface UserRoomsOutputProps {
  roomType: RoomType
  roomId: string
  roomName: string
  roomDescription?: string
  roomPhotoLink?: string // only relevant for private chats
  roomAdmins?: string[]
  roomMemberCount?: number
  roomMembers?: string[]
  roomCommunityId?: string
  roomCommunityActivity?: string
  roomCreatedBy?: string
  roomCreatedAt: string
  roomUpdatedAt: string
  userIsMember?: boolean
  // returned only if user is member
  roomLastMsgs?: {
    msgText: string
    msgSentBy: string
    msgSentAt: string
  }[]
}
export interface RoomUsersOutputProps {
  userId: string
  userName: string
  userPhotoLink: string
  userLastSeenAt: string
  createdAt: string
  updatedAt: string
}
