import { App } from '@capacitor/app'
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications'
import { isPushNotification, isWeb } from './constants'
import { updateUser } from './users'

export const registerApp = async (onAppStateActive: () => Promise<void>) => {
  const handleAppStateChange = async (isActive: boolean) => {
    if (isActive) {
      await onAppStateActive()
    }
  }

  if (isWeb) {
    // Web: Handle visibility changes
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', () => {
        handleAppStateChange(!document.hidden)
      })
      // Trigger on initial load to simulate active state
      handleAppStateChange(!document.hidden)
    }
  } else {
    // Mobile: Handle app state changes using Capacitor
    App.addListener('appStateChange', ({ isActive }) => {
      handleAppStateChange(isActive)
    })
    // Trigger on initial load to simulate active state
    const { isActive } = await App.getState()
    handleAppStateChange(isActive)
  }
}

export const registerNotifications = async (userId: string): Promise<void> => {
  try {
    if (!isPushNotification) return
    // Registration
    let permStatus: PermissionStatus = await PushNotifications.checkPermissions()
    if (permStatus.receive !== 'granted') permStatus = await PushNotifications.requestPermissions()
    if (permStatus.receive === 'granted') PushNotifications.register()
    // Listener to trap registration events
    PushNotifications.addListener('registrationError', (error: any) => console.error('Error registering:', error))
    PushNotifications.addListener('registration', async (token: Token) => {
      try {
        // Add the device to user profile
        await updateUser(userId, undefined, undefined, undefined, undefined, undefined, token.value)
      } catch (error) {
        console.error('Error registering notification device:', error)
        // Optional: Show a user-facing error message or retry mechanism
      }
    })

    // Listener to remove all delivered notifications when app is open
    PushNotifications.addListener('pushNotificationReceived', async (n: PushNotificationSchema) => {
      // Check if the notification is a validation message
      if (n.data?.validation !== 'check' && n.data?.url) {
        window.location.replace(n.data.url)
      }
    })
    // Listener to open the appropriate page when app is closed
    PushNotifications.addListener('pushNotificationActionPerformed', (a: ActionPerformed) => {
      // Check if the notification is a validation message
      if (a.notification.data?.validation !== 'check' && a.notification.data?.url) {
        window.location.replace(a.notification.data.url)
      }
    })
  } catch (error) {
    console.error('Error initializing push notifications:', error)
  }
}
