import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import ButtonMobileComponent from 'components/button-mobile'
import CircularProgressWithLabel from 'components/table/CircularProgressLabel'
import ToastComponent from 'components/toast'
import { useState } from 'react'
import { MessageProps } from 'utils/types'

const StripePaymentComponent = (props: any) => {
  const { isExisting, onPayment } = props
  const stripe = useStripe()
  const elements = useElements()
  const [isLoading, setIsLoading] = useState<string>('')
  const [isPaymentFormComplete, setIsPaymentFormComplete] = useState<boolean>(false)
  const [isConfirmPayment, setIsConfirmPayment] = useState<boolean>(false)
  const [msg, setMsg] = useState<MessageProps>()

  const handleSubmit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      setMsg({
        style: 'error',
        heading: 'Our payment processor is down',
        text: `Stripe failed to load. Please try again later. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
      })
      return
    }

    setIsLoading('Processing payment information...')
    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      redirect: 'if_required',
    })

    if (error?.type) {
      setMsg({
        style: 'error',
        heading: ['card_error', 'validation_error'].includes(error.type)
          ? 'Card Processing Error'
          : 'Oops, Something Went Wrong',
        text: ['card_error', 'validation_error'].includes(error.type)
          ? error.message
          : `We encountered an issue while processing your request. Please try your operation again. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
      })
    } else {
      setIsConfirmPayment(true)
      onPayment(setupIntent?.payment_method)
    }
    setIsLoading('')
  }

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && (
        <Dialog open={true}>
          <DialogTitle>{isLoading}</DialogTitle>
          <CircularProgressWithLabel />
        </Dialog>
      )}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}

      {/***************** Stripe Payment Element *****************/}
      <PaymentElement id='payment-element' onChange={(e) => setIsPaymentFormComplete(e.complete)} />
      {!isConfirmPayment && (
        <div className='w-full flex items-center justify-center gap-5 mt-4'>
          <ButtonMobileComponent
            text={isLoading ? 'Processing... ' : isExisting ? 'Update Payment Method' : 'Add Payment Method'}
            width='w-[230px]'
            disabled={isLoading || !isPaymentFormComplete ? true : false}
            onClick={handleSubmit}
          />
        </div>
      )}
    </>
  )
}

export default StripePaymentComponent
