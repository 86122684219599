import LocationOnIcon from '@mui/icons-material/LocationOn'
import SearchIcon from '@mui/icons-material/Search'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import InputAdornment from '@mui/material/InputAdornment'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import ToastComponent from 'components/toast'
import { useEffect, useState } from 'react'
import { CommunityType, EnrolledCommunityOutputProps } from 'types/CommunityTypes'
import { getEnrolledCommunities } from 'utils/communities'
import { baseURL, titleCase } from 'utils/constants'
import { MessageProps } from 'utils/types'

const TypeaheadComponent = ({
  isEnrolledOnly,
  onChange,
}: {
  isEnrolledOnly: boolean
  onChange: (v: any) => Promise<void>
}) => {
  const labelKey = (v: any) =>
    `${titleCase(v?.communityName)}, ${v?.locality}, ${v?.adminArea}, ${v?.postalCode} ${v?.country}`
  const [enrolledCommunities, setEnrolledCommunities] = useState<EnrolledCommunityOutputProps[]>([])
  const [value, setValue] = useState<any | null>(null)
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly any[]>([])
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // HOOKS
  useEffect(() => {
    if (!isEnrolledOnly) return

    const fetchEnrolledCommunities = async () => {
      const { msg, data, error } = await getEnrolledCommunities(CommunityType.SCHOOL)

      if (error) {
        setMsg(msg)
      } else if (data) {
        setEnrolledCommunities(data.communities)
      }
    }

    fetchEnrolledCommunities()
  }, [isEnrolledOnly])

  useEffect(() => {
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }

    if (isEnrolledOnly) {
      const newOptions: EnrolledCommunityOutputProps[] = []
      enrolledCommunities.forEach((v: EnrolledCommunityOutputProps) => {
        const { communityName, country, adminArea, locality, postalCode } = v
        const isKeywordFound: boolean =
          !inputValue ||
          [communityName, country, adminArea, locality, postalCode].some(
            (field) => field && field.toLowerCase().includes(inputValue.toLowerCase()),
          )
        if (isKeywordFound) {
          newOptions.push(v)
        }
      })
      setOptions(newOptions)
    } else {
      const optionsUrl = `${baseURL}/api/communities?communityType=${CommunityType.SCHOOL}&communityName=`
      const optionsKey = 'communities'
      setIsLoading(true)
      const getData = setTimeout(() => {
        fetch(`${optionsUrl}${encodeURIComponent(inputValue)}`)
          .then((resp) => resp.json())
          .then((json) => {
            let newOptions: readonly any[] = []
            if (value) newOptions = [value]
            if (json) newOptions = [...newOptions, ...json[optionsKey]]
            setOptions(newOptions)
          })
          .catch((error) => {
            return
          })
          .finally(() => setIsLoading(false))
      }, 400)

      return () => clearTimeout(getData)
    }
  }, [isEnrolledOnly, enrolledCommunities, value, inputValue])

  // Custom Paper component with rounded corners
  const CustomPaper = (props: any) => <Paper {...props} sx={{ borderRadius: '8px' }} />

  return (
    <center>
      {/***************** Display progress and error *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Autocomplete component *****************/}
      <div className=''>
        <div className='text-left text-sm mb-2'>School Name</div>
        <Autocomplete
          getOptionLabel={labelKey}
          filterOptions={(x) => x}
          options={options}
          data-testid='school-name-dropdown'
          autoComplete
          includeInputInList
          filterSelectedOptions
          value={value}
          loading={isLoading}
          sx={{ width: '100%', '& .MuiAutocomplete-inputRoot': { borderRadius: '8px' } }}
          loadingText={inputValue ? 'Loading schools...' : 'Schools starting with: None entered.'}
          noOptionsText={inputValue ? 'No matches found.' : 'Schools starting with: None entered.'}
          onChange={(event: any, newValue: any | null) => {
            setOptions(newValue ? [newValue, ...options] : options)
            setValue(newValue)
            onChange(newValue)
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue)
          }}
          PaperComponent={CustomPaper}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder='Begin typing your school name...'
              fullWidth
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position='start'>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
          renderOption={(props, option) => {
            const { communityId, communityName, locality, adminArea, postalCode, country } = option

            return (
              <li {...props} key={communityId}>
                <Grid container alignItems='center'>
                  <Grid item sx={{ display: 'flex', width: 44 }}>
                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                  </Grid>
                  <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                    <Typography variant='body2'>{titleCase(communityName)}</Typography>
                    <Typography variant='body2' color='text.secondary'>
                      {`${locality.toUpperCase()}, ${adminArea.toUpperCase()}, ${postalCode} ${country}`}
                    </Typography>
                  </Grid>
                </Grid>
              </li>
            )
          }}
        />
      </div>
    </center>
  )
}

export default TypeaheadComponent
