import MapComponent from 'components/map'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import React, { useState } from 'react'
import { getMarker, reverseGeocode } from 'utils/map'
import { LocationProps, MarkerProps } from 'utils/types'
import './index.css'

dayjs.extend(customParseFormat)

interface MapPinComponentProps {
  onSave: (v: LocationProps) => void
  currentLocation: LocationProps
  mapHeight?: any
  customHeight?: any
}

const MapPinComponent: React.FC<MapPinComponentProps> = ({
  onSave,
  currentLocation,
  mapHeight, // Height of the pin location map
  customHeight,
}) => {
  const [centerMarker, setCenterMarker] = useState<MarkerProps>(getMarker(currentLocation, 'You', undefined, true))
  const [markers, setMarkers] = useState<MarkerProps[]>([getMarker(currentLocation, 'You', undefined, true)])
  const [location, setLocation] = useState<LocationProps>({ address: currentLocation.address || '' })

  //  function for getting new address for pin location
  const onAddressUpdate = async (location: LocationProps) => {
    setLocation(location)
    setCenterMarker(getMarker(location, 'You', undefined, true))
    setMarkers([getMarker(location, 'You', undefined, true)])
  }
  const onAddressSave = async () => {
    try {
      const lat = location.lat ?? currentLocation.lat
      const lng = location.lng ?? currentLocation.lng
      if (lat === undefined || lng === undefined) throw new Error('Address drag results missing lat & lng')
      const geocodedLocation: LocationProps = await reverseGeocode(lat, lng)
      onSave(geocodedLocation)
    } catch (error) {
      console.error('Error reverse geocoding the moved marker:', error)
    }
  }

  return (
    <div
      style={{
        position: 'relative',
        border: '1px solid #F0E977',
        borderRadius: '15px',
        width: '100%',
        backgroundColor: 'white',
      }}
    >
      {/* Map component with pin location enabled */}
      <MapComponent
        centerMarker={centerMarker}
        markers={markers}
        onAddressUpdate={onAddressUpdate}
        mapHeight={mapHeight}
        customHeight={customHeight} // height of the map in web and mobile versions
        borderRadius='15px'
      />
      {/* going to back to afternoon page without saving */}

      {/* Saving the address */}
      <button
        onClick={() => onAddressSave()}
        className='flex w-20 h-10 text-sm justify-center items-center rounded-lg bg-[#F0E977] absolute bottom-5 right-5'
        aria-label='More options'
      >
        Save&nbsp;
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          role='img'
          aria-label='More options'
        >
          <path
            d='M9.99992 3.33301L8.82492 4.50801L13.4749 9.16634H3.33325V10.833H13.4749L8.82492 15.4913L9.99992 16.6663L16.6666 9.99967L9.99992 3.33301Z'
            fill='#474747'
          />
        </svg>
      </button>
    </div>
  )
}

export default MapPinComponent
