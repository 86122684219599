import CenterComponent from 'components/center'
import MetricsComponent from 'components/metrics'
import ToastComponent from 'components/toast'
import TypeaheadComponent from 'components/typeahead'
import { useEffect, useState } from 'react'
import { getSchool, getSchoolStats } from 'utils/schools'
import { MessageProps } from 'utils/types'
import './index.css'
import { SchoolStatProps } from 'types/SchoolTypes'

function Index(): JSX.Element {
  const [schoolName, setSchoolName] = useState<string>()
  const [schoolStats, setSchoolStats] = useState<SchoolStatProps | undefined>()
  const [msg, setMsg] = useState<MessageProps>()

  useEffect(() => {
    if (schoolName) {
      (async function () {
        const { data } = await getSchoolStats(schoolName)
        setSchoolStats(data?.schoolStats)
      })()
    }
  }, [schoolName]) // eslint-disable-line

  return (
    <>
      <CenterComponent>
        <TypeaheadComponent
          isEnrolledOnly={true}
          onChange={async (v: any) => {
            if (!v || !v.communityName || !v.communityId) return
            let schoolId: string = `${v.communityName}-${v.communityId}`
            const { msg, data, error } = await getSchool(schoolId)
            if (error) setMsg(msg)
            else if (data?.status === 'missing') schoolId = v.communityName
            setSchoolName(schoolId)
          }}
        />
        {msg ? (
          <ToastComponent
            style={msg?.style}
            heading={msg?.heading}
            text={msg?.text}
            onClose={() => setMsg(undefined)}
          />
        ) : (
          <></>
        )}
      </CenterComponent>
      {schoolStats && <MetricsComponent schoolStats={schoolStats} />}
    </>
  )
}
export default Index
