import { CameraSource } from '@capacitor/camera'
import { Box } from '@mui/material'
import LinearProgress from '@mui/material/LinearProgress'
import BackComponent from 'components/back'
import ButtonMenuComponent from 'components/button-menu'
import ButtonMobileComponent from 'components/button-mobile'
import { getCameraPhoto } from 'components/camera'
import {
  DefaultProfileIcon,
  DeleteIcon,
  FacebookIcon,
  InstagramIcon,
  LinkedinIcon,
  MoreVerticalIcon,
  TakePhotoIcon,
  UploadPhotoIcon,
  ViewPhotoIcon,
} from 'components/icons'
import InputMobileComponent from 'components/input-mobile'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import SelectMobileComponent from 'components/select-mobile'
import TextAreaComponent from 'components/textarea'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { parsePhoneNumber } from 'libphonenumber-js/max'
import { useEffect, useRef, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { UserOutputProps } from 'types/UserTypes'
import { isWeb, parseJwt } from 'utils/constants'
import { enabledRelationships, updateStudentSettings } from 'utils/students'
import { MessageProps, StudentProps } from 'utils/types'
import { deleteUserPhoto, updateUser, uploadUserPhoto } from 'utils/users'
import { isValidComments, isValidName, isValidRelationship, isValidUrl } from 'utils/validations'
import Arrow from '../../images/arrow_forward.png'
import './index.css'
// import Webcam from "react-webcam";

interface MenuComponentProps {
  image: string
  isLeftAligned?: boolean
  menu?: JSX.Element
  onView?: () => void
  onTake?: (f?: FormData) => Promise<void>
  onUpload?: (f?: FormData) => Promise<void>
  onRemove?: () => Promise<void>
}

const MenuComponent = (props: MenuComponentProps): JSX.Element => {
  // inputs
  const { image, isLeftAligned, menu, onView, onTake, onUpload, onRemove } = props ?? {}
  const isImage: boolean = image ? true : false
  // states to control menu state
  const [isOpen, setIsOpen] = useState<boolean>(false)
  // html references
  const panelRef = useRef<HTMLDivElement>(null)

  //hooks
  useEffect(() => {
    if (isOpen) {
      const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
        const target = event.target as Node
        if (panelRef.current && !panelRef.current.contains(target)) {
          // Ignore if the target is a button or any clickable element inside the modal
          if (!(target instanceof HTMLButtonElement)) {
            setIsOpen(false)
          }
        }
      }
      document.addEventListener('mousedown', handleOutsideInteraction)
      document.addEventListener('touchstart', handleOutsideInteraction)
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction)
        document.removeEventListener('touchstart', handleOutsideInteraction)
      }
    }
  }, [isOpen, panelRef])

  return (
    <div ref={panelRef} className='relative'>
      {/***************** Show menu button *****************/}
      <button className='flex items-center' onClick={() => setIsOpen(!isOpen)}>
        {menu ?? <MoreVerticalIcon />}
      </button>
      {/***************** Show menu items *****************/}
      {isOpen && (
        <div
          className={`dropdown absolute top-full ${isLeftAligned ? 'left-0' : 'right-0'} mt-2 min-w-[200px] flex flex-col items-start  pb-2 overflow-y-auto bg-white rounded-lg shadow-lg border border-gray-300 z-10`}
        >
          {isImage && onView && (
            <ButtonMenuComponent
              name='View photo'
              leftIcon={<ViewPhotoIcon />}
              onClick={() => {
                setIsOpen(false)
                onView()
              }}
            />
          )}
          {onTake && !isWeb && (
            <>
              <hr className='w-full border-t border-gray-300' />
              <ButtonMenuComponent
                name='Take photo'
                leftIcon={<TakePhotoIcon />}
                onClick={async (): Promise<void> => {
                  setIsOpen(false)
                  const formData: FormData | undefined = await getCameraPhoto(CameraSource.Camera)
                  onTake(formData)
                }}
              />
            </>
          )}
          {onUpload && (
            <>
              <hr className='w-full border-t border-gray-300' />
              <ButtonMenuComponent
                name='Upload photo'
                leftIcon={<UploadPhotoIcon />}
                onClick={async (): Promise<void> => {
                  setIsOpen(false)
                  const formData: FormData | undefined = await getCameraPhoto(CameraSource.Photos)
                  onUpload(formData)
                }}
              />
            </>
          )}
          {isImage && onRemove && (
            <>
              <hr className='w-full border-t border-gray-300' />
              <ModalConfirmRemoveComponent
                data_testid='DeleteOutlinedIcon'
                icon={
                  <div className='flex items-center gap-[6px] self-stretch px-[20px] py-[12px] text-headings w-full hover:bg-gray-100'>
                    <DeleteIcon />
                    <div className='text-headings text-sm font-figtree text-[14px] font-medium leading-[20px] flex-grow cursor-pointer'>
                      Remove Photo
                    </div>
                  </div>
                }
                title='Delete Photo'
                body={`Are you sure you want to delete your profile photo?`}
                actionText='Delete now'
                onClick={async () => {
                  setIsOpen(false)
                  await onRemove()
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  )
}

function Index({
  tokens,
  students,
  user,
  userPhoto,
  userStudentRelationship,
  onUpdate,
}: {
  tokens: any
  students: StudentProps[]
  user?: UserOutputProps
  userPhoto: string
  userStudentRelationship?: string
  onUpdate: (cb?: () => void) => void
}): JSX.Element {
  // Inputs: Tokens, Students, User Relation, and Callback
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number
  const navigate: NavigateFunction = useNavigate()

  // states
  const [userDetails, setUserDetails] = useState<UserOutputProps>()
  const [userImage, setUserImage] = useState<string>()
  const [userRelationship, setUserRelationship] = useState<string>()
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')
  const [isViewingPhoto, setIsViewingPhoto] = useState<boolean>(false)

  // hooks
  useEffect(() => {
    setUserDetails(user)
    setUserImage(userPhoto)
    setUserRelationship(userStudentRelationship ?? '')
  }, [user, userPhoto, userStudentRelationship])

  // constants
  if (!userDetails || userImage === undefined) return <LinearProgress />
  const { userFirstName, userLastName, userBio, userSocialLinks } = userDetails
  const isProfileValid: boolean =
    isValidName(userFirstName, true) &&
    isValidName(userLastName, true) &&
    isValidComments(userBio) &&
    isValidUrl(userSocialLinks?.facebook) &&
    isValidUrl(userSocialLinks?.linkedin) &&
    isValidUrl(userSocialLinks?.instagram)

  // handlers
  const onView = () => setIsViewingPhoto(true)
  const onUpload = async (formData?: FormData) => {
    if (formData) {
      setIsLoading('Uploading user profile photo...')
      const { msg, error } = await uploadUserPhoto(phoneNumber, formData)
      if (error) {
        setMsg(msg)
        setIsLoading('')
      } else onUpdate(() => setIsLoading(''))
    }
  }
  const onRemove = async () => {
    setIsLoading('Removing user profile photo...')
    const { msg, error } = await deleteUserPhoto(phoneNumber)
    if (error) {
      setMsg(msg)
      setIsLoading('')
    } else onUpdate(() => setIsLoading(''))
  }

  // edit profile image
  const editProfileImage: JSX.Element = userImage ? (
    <img
      src={userImage}
      alt='Profile'
      className={`w-14 h-14 rounded-full shadow-sm object-cover`}
      onError={() => setUserImage('')} // Mark as invalid if loading fails
    />
  ) : (
    <DefaultProfileIcon />
  )
  // viewing profile image
  const viewProfileImage: JSX.Element = userImage ? (
    <img
      src={userImage}
      alt='Profile'
      onError={() => setUserImage('')} // Mark as invalid if loading fails
    />
  ) : (
    <p className='flex justify-center items-center mt-10'>No image uploaded</p>
  )

  // edit profile - body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px] mt-4'}`}>
      <div className='flex justify-between'>
        <div className='flex items-center justify-center w-14 h-14 rounded-full border  border-gray-200 bg-gray-100 relative profile-menu-toggle-icon'>
          <MenuComponent
            image={userImage}
            isLeftAligned={true}
            menu={editProfileImage}
            onView={onView}
            onTake={onUpload}
            onUpload={onUpload}
            onRemove={onRemove}
          />
          <div className='absolute top-9 left-9 w-5 h-5 p-0.5 rounded-full bg-yellow-200'>
            <svg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 20 20' fill='none'>
              <path
                d='M11.7156 7.51667L12.4822 8.28333L4.93223 15.8333H4.16557V15.0667L11.7156 7.51667ZM14.7156 2.5C14.5072 2.5 14.2906 2.58333 14.1322 2.74167L12.6072 4.26667L15.7322 7.39167L17.2572 5.86667C17.5822 5.54167 17.5822 5.01667 17.2572 4.69167L15.3072 2.74167C15.1406 2.575 14.9322 2.5 14.7156 2.5ZM11.7156 5.15833L2.4989 14.375V17.5H5.6239L14.8406 8.28333L11.7156 5.15833Z'
                fill='#474747'
              />
            </svg>
          </div>
        </div>
        <span
          className={`max-h-10 max-w-60 text-[10px] rounded-xl px-2 py-1 cursor-pointer bg-light-yellow border-custom-yellow border text-gray-800`}
        >
          The information you share will help other parents get to know you for carpooling.
        </span>
      </div>

      <Box display='flex' flexDirection='column' justifyContent='center' paddingTop={2} gap={2}>
        {/* First and Last Name */}
        <Box display='flex' flexDirection='row' justifyContent='space-between' paddingTop={2} gap={2}>
          <InputMobileComponent
            type='text'
            labeltext='First Name'
            data_testid='first_name_testid'
            isRequired={true}
            isError={!isValidName(userFirstName)}
            value={userFirstName}
            onChange={(v) => setUserDetails({ ...userDetails, userFirstName: v })}
          />
          <InputMobileComponent
            type='text'
            labeltext='Last Name'
            data_testid='last_name_testid'
            isRequired={true}
            isError={!isValidName(userLastName)}
            value={userLastName}
            onChange={(v) => setUserDetails({ ...userDetails, userLastName: v })}
          />
        </Box>

        {/* Phone Number */}
        <InputMobileComponent
          labeltext='Phone Number'
          type='tel'
          isReadOnly={true}
          onChange={(v: string) => v}
          value={parsePhoneNumber(phoneNumber).formatNational()}
          isDisabled={true}
          data_testid='Signin-Phone-number-testid'
        />

        {/* Relationship */}
        {userRelationship && (
          <>
            <SelectMobileComponent
              label='Relationship'
              data_testid='relationship_testid'
              isRequired={true}
              isError={!isValidRelationship(userRelationship)}
              options={enabledRelationships}
              value={userRelationship}
              onChange={(v: string) => setUserRelationship(v)}
            />
          </>
        )}

        {/* Add comments */}
        <div className='w-full mt-1'>
          <TextAreaComponent
            labeltext='About your family'
            placeholder='Share a bit about you to help others connect'
            height='h-[96px]'
            isRequired={false}
            onChange={(v) => setUserDetails({ ...userDetails, userBio: v })}
            value={userDetails?.userBio}
            isError={!isValidComments(userBio)}
          />
        </div>

        {/* Add Social Links Section */}
        <div>
          <p className='text-gray-400 text-sm font-medium leading-[1.42857]'>Social Links</p>
          <InputMobileComponent
            placeholder='www.facebook.com'
            rightElement={<FacebookIcon />}
            onChange={(v) => setUserDetails({ ...userDetails, userSocialLinks: { ...userSocialLinks, facebook: v } })}
          />
          <InputMobileComponent
            placeholder='www.linkedin.com'
            rightElement={<LinkedinIcon />}
            onChange={(v) => setUserDetails({ ...userDetails, userSocialLinks: { ...userSocialLinks, linkedin: v } })}
          />
          <InputMobileComponent
            placeholder='www.instagram.com'
            rightElement={<InstagramIcon />}
            onChange={(v) => setUserDetails({ ...userDetails, userSocialLinks: { ...userSocialLinks, instagram: v } })}
          />
        </div>
      </Box>
    </div>
  )
  // edit profile - footer
  const footer: JSX.Element = (
    <div className='flex justify-around gap-3'>
      <ButtonMobileComponent text='Cancel' color='bg-btn-grey' onClick={() => navigate('/')} />
      <ButtonMobileComponent
        disabled={!isProfileValid}
        text={'Save'}
        iconPosition='right'
        icon={Arrow}
        onClick={async () => {
          setIsLoading('Updating user profile...')
          try {
            const userName: string = `${userFirstName} ${userLastName}`
            // Update User Profile
            const res1 = await updateUser(phoneNumber, userFirstName, userLastName, userBio, userSocialLinks)
            if (res1?.error) throw new Error('User name update failed')

            // Update User Student Relationships
            const res2 = await Promise.all(
              students.map(async (s: StudentProps) => {
                const { schoolName, studentId } = s
                return await updateStudentSettings(schoolName, studentId, userName, userRelationship)
              }),
            )
            res2.forEach((v: any) => {
              if (v?.error) throw new Error('User relationship update failed')
            })
            setMsg({ style: 'success', text: 'Profile updated successfully.' })
          } catch (error) {
            setMsg({
              style: 'error',
              heading: 'Oops, Something Went Wrong',
              text: `We encountered an issue while processing your request. Please try your operation again. If the issue persists, please don't hesitate to contact our support team at support@carpool.school. We'll be happy to assist you further. Thank you for your understanding.`,
            })
          }
          onUpdate(() => setIsLoading(''))
        }}
      />
    </div>
  )

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Mobile - Edit Profile *****************/}
      {!isWeb && !isViewingPhoto && (
        <BackComponent text={'Edit Profile'}>
          {body}
          <div className='fixed bottom-0 left-0 right-0 bg-[#fff] pt-3'>
            <div className='border-b border-gray-200'></div>
            <div className='pt-4 pb-7 px-5'>{footer}</div>
          </div>
        </BackComponent>
      )}
      {/***************** Mobile - View Photo *****************/}
      {!isWeb && isViewingPhoto && (
        <BackComponent
          text='Profile Photo'
          onClick={() => setIsViewingPhoto(false)}
          rightElement={<MenuComponent image={userImage} onTake={onUpload} onUpload={onUpload} onRemove={onRemove} />}
        >
          {viewProfileImage}
        </BackComponent>
      )}
      {/***************** Web - Edit Profile *****************/}
      {isWeb && !isViewingPhoto && (
        <WizardComponent title={'Edit Profile'}>
          <>
            {body}
            {footer}
          </>
        </WizardComponent>
      )}
      {/***************** Web - View Photo *****************/}
      {isWeb && isViewingPhoto && (
        <WizardComponent
          title={'Profile Photo'}
          onBack={async () => setIsViewingPhoto(false)}
          rightElement={<MenuComponent image={userImage} onTake={onUpload} onUpload={onUpload} onRemove={onRemove} />}
        >
          {viewProfileImage}
        </WizardComponent>
      )}
    </>
  )
}

export default Index
