import LinearProgress from '@mui/material/LinearProgress'
import BackComponent from 'components/back'
import {
  AnnouncementIcon,
  GeneralNotificationIcon,
  PersonalizedReminderIcon,
  ProductUpdateIcon,
  UrgentReminderIcon,
} from 'components/icons'
import SwitchComponent from 'components/switch'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useEffect, useState } from 'react'
import { NotifyTypes, UserOutputProps } from 'types/UserTypes'
import { isWeb, parseJwt } from 'utils/constants'
import { MessageProps } from 'utils/types'
import { updateUser } from 'utils/users'
import './index.css'

interface CardComponentProps {
  avatar: JSX.Element
  title: string
  body: string | JSX.Element
  isChecked: boolean
  isDisabled?: boolean
  onSwitch?: () => Promise<void>
}

const CardComponent = (props: CardComponentProps): JSX.Element => {
  const { avatar, title, body, isChecked, isDisabled = false, onSwitch } = props ?? {}

  return (
    <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
      <div className='flex justify-between items-start self-stretch'>
        <div className='flex items-start gap-2 flex-1'>
          {avatar}
          <div className='flex flex-col gap-1'>
            {/***************** Title *****************/}
            <div className='flex items-center gap-1 max-w-[60vw]'>
              {/*  Do not truncate title & wrap it if there is no subtitle to render */}
              <span className={`max-w-[60vw] w-auto text-[#474747] text-sm font-medium`}>{title}</span>
            </div>
            {/***************** Body *****************/}
            <span className='text-[#797979] text-xs font-regular max-w-[60vw]'>{body}</span>
          </div>
        </div>
        <div className='flex flex-col items-end text-[#797979] text-xs font-regular ml-auto'>
          {isChecked !== undefined && onSwitch && (
            <SwitchComponent
              isChecked={isChecked}
              disable={isDisabled}
              onChange={onSwitch}
              placement='start'
              color='secondary'
            />
          )}
        </div>
      </div>
    </div>
  )
}

function Index({
  tokens,
  user,
  onUpdate,
}: {
  tokens: any
  user?: UserOutputProps
  onUpdate: (cb?: () => void) => void
}): JSX.Element {
  // Inputs: Tokens, Students, User Relation, and Callback
  const { IdToken } = tokens
  const phoneNumber: string = parseJwt(IdToken).phone_number

  // states
  const [userNotificationTypesDisabled, setUserNotificationTypesDisabled] = useState<number>()
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<string>('')

  // hooks
  useEffect(() => {
    setUserNotificationTypesDisabled(user?.userNotificationTypesDisabled ?? 0)
  }, [user])

  // constants
  if (userNotificationTypesDisabled === undefined) return <LinearProgress />

  // handlers
  const handleUpdate = async (type: NotifyTypes) => {
    setIsLoading('Update notifications...')
    const n: number = userNotificationTypesDisabled ^ type
    setUserNotificationTypesDisabled(n)
    const { msg, error } = await updateUser(phoneNumber, undefined, undefined, undefined, undefined, n)
    if (error) setMsg(msg)
    else onUpdate(() => setIsLoading(''))
  }

  // body
  const body: JSX.Element = (
    <div className={`flex flex-col flex-1 self-stretch gap-2 ${isWeb ? '' : 'px-5 mb-[120px]'}`}>
      <CardComponent
        avatar={<UrgentReminderIcon />}
        title={'Key Reminders'}
        body={'Critical alerts that need your attention cannot be turned off.'}
        isChecked={userNotificationTypesDisabled & NotifyTypes.URGENT_REMINDERS ? false : true}
        isDisabled={true}
        onSwitch={() => handleUpdate(NotifyTypes.URGENT_REMINDERS)}
      />
      <CardComponent
        avatar={<AnnouncementIcon />}
        title={'School Announcements'}
        body={'Notifications from school admins cannot be disabled.'}
        isChecked={userNotificationTypesDisabled & NotifyTypes.COMMUNITY_ANNOUNCEMENTS ? false : true}
        isDisabled={true}
        onSwitch={() => handleUpdate(NotifyTypes.COMMUNITY_ANNOUNCEMENTS)}
      />
      <CardComponent
        avatar={<PersonalizedReminderIcon />}
        title={'Personalized Reminders'}
        body={'Stay updated on nearby families carpooling, new school activities, and more.'}
        isChecked={userNotificationTypesDisabled & NotifyTypes.SMART_REMINDERS ? false : true}
        onSwitch={() => handleUpdate(NotifyTypes.SMART_REMINDERS)}
      />
      <CardComponent
        avatar={<GeneralNotificationIcon />}
        title={'General Notifications'}
        body={'Receive reminders on schedules, group activities, and more.'}
        isChecked={userNotificationTypesDisabled & NotifyTypes.GENERAL_UPDATES ? false : true}
        onSwitch={() => handleUpdate(NotifyTypes.GENERAL_UPDATES)}
      />
      <CardComponent
        avatar={<ProductUpdateIcon />}
        title={'Product Updates'}
        body={'Learn about new features and periodic surveys to improve your community app.'}
        isChecked={userNotificationTypesDisabled & NotifyTypes.MARKETING_UPDATES ? false : true}
        onSwitch={() => handleUpdate(NotifyTypes.MARKETING_UPDATES)}
      />
      <div className={`flex flex-col items-start gap-3 my-4 self-stretch lg:p-2 lg:border lg:rounded-xl`}>
        <div className='flex justify-between items-start self-stretch'>
          <div className='flex flex-col gap-1 flex-1'>
            {/***************** Title *****************/}
            <div className='flex items-center gap-1 max-w-[80vw]'>
              {/* Do not truncate title & wrap it if there is no subtitle to render */}
              <span className={`max-w-[80vw] w-auto text-[#474747] text-sm font-medium`}>
                Steps to Turn On Notifications for Your Device
              </span>
            </div>
            {/***************** Body *****************/}
            <div className='text-[#797979] text-xs font-regular max-w-[80vw]'>
              <ol style={{ listStyle: 'decimal', paddingLeft: '20px' }}>
                <li>
                  Open your phone’s <strong>Settings</strong> and select <strong>Apps</strong>.
                </li>
                <li>
                  Find and select <strong>Carpool.School</strong> from the list.
                </li>
                <li>
                  Tap <strong>Notifications</strong> and enable it.
                </li>
              </ol>
              <p style={{ marginTop: '4px' }}>Delete and reinstall the app if you are having trouble.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      {/***************** Display error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Mobile *****************/}
      {!isWeb && <BackComponent text={'Manage Notifications'}>{body}</BackComponent>}
      {/***************** Web *****************/}
      {isWeb && <WizardComponent title={'Manage Notifications'}>{body}</WizardComponent>}
    </>
  )
}

export default Index
