import MapComponent from 'components/map'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat.js'
import React, { useState } from 'react'
import { getMarker, reverseGeocode } from 'utils/map'
import { LocationProps, MarkerProps } from 'utils/types'
import { Drawer } from 'vaul'
import './index.css'

dayjs.extend(customParseFormat)

interface MapPinMobileComponentProps {
  onClose: () => void
  onSave: (v: LocationProps) => void
  currentLocation: LocationProps
  mapHeight?: any
  customHeight?: any
}

const MapPinMobileComponent: React.FC<MapPinMobileComponentProps> = ({
  onClose,
  onSave,
  currentLocation,
  mapHeight, // Height of the pin location map
  customHeight,
}) => {
  const [centerMarker, setCenterMarker] = useState<MarkerProps>(getMarker(currentLocation, 'You', undefined, true))
  const [markers, setMarkers] = useState<MarkerProps[]>([getMarker(currentLocation, 'You', undefined, true)])
  const [location, setLocation] = useState<LocationProps>({ address: currentLocation.address || '' })
  const [snap, setSnap] = useState<string | number | null>(0.25)
  const handleSetSnap = (snapPoint: string | number | null) => {
    setSnap(snapPoint)
  }

  //  function for getting new address for pin location
  const onAddressUpdate = async (location: LocationProps) => {
    setLocation(location)
    setCenterMarker(getMarker(location, 'You', undefined, true))
    setMarkers([getMarker(location, 'You', undefined, true)])
  }
  const onAddressSave = async () => {
    try {
      const lat = location.lat ?? currentLocation.lat
      const lng = location.lng ?? currentLocation.lng
      if (lat === undefined || lng === undefined) throw new Error('Address drag results missing lat & lng')
      const geocodedLocation: LocationProps = await reverseGeocode(lat, lng)
      onSave(geocodedLocation)
    } catch (error) {
      console.error('Error reverse geocoding the moved marker:', error)
    }
  }

  const [boldPart, restOfAddress] = location.address.split(', ').reduce(
    (acc, curr, index, arr) => {
      if (arr.length !== 3) {
        if (index < arr.length - 3) {
          acc[0] += curr + ', '
        } else {
          acc[1] += curr + (index < arr.length - 1 ? ', ' : '')
        }
      } else {
        if (index < arr.length - 2) {
          acc[0] += curr + ', '
        } else {
          acc[1] += curr + (index < arr.length - 1 ? ', ' : '')
        }
      }
      return acc
    },
    ['', ''],
  )

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
        zIndex: 10,
      }}
    >
      {/* Map component with pin location enabled */}
      <MapComponent
        centerMarker={centerMarker}
        markers={markers}
        onAddressUpdate={onAddressUpdate}
        mapHeight={mapHeight}
        customHeight={customHeight}
      />
      {/* going to back to afternoon page without saving */}
      <button
        onClick={onClose}
        className='CloseBtn'
        style={{ backgroundColor: 'white', padding: '10px', borderRadius: '20px', marginLeft: '15px' }}
      >
        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' viewBox='0 0 22 22' fill='none'>
          <path
            d='M22 9.625H5.26625L12.9525 1.93875L11 0L0 11L11 22L12.9387 20.0613L5.26625 12.375H22V9.625Z'
            fill='#68727D'
          />
        </svg>
      </button>
      {/* Saving the address */}
      <button
        onClick={() => onAddressSave()}
        className='flex w-20 h-10 text-sm justify-center items-center rounded-lg bg-[#F0E977] SaveBtn'
        aria-label='More options'
      >
        Save&nbsp;
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          role='img'
          aria-label='More options'
        >
          <path
            d='M9.99992 3.33301L8.82492 4.50801L13.4749 9.16634H3.33325V10.833H13.4749L8.82492 15.4913L9.99992 16.6663L16.6666 9.99967L9.99992 3.33301Z'
            fill='#474747'
          />
        </svg>
      </button>
      {/* Location drawer component */}
      <div data-vaul-drawer-wrapper=''>
        <Drawer.Root
          snapPoints={[0.25]}
          activeSnapPoint={snap}
          setActiveSnapPoint={handleSetSnap}
          open={true}
          modal={false}
        >
          <Drawer.Portal>
            <Drawer.Overlay className='fixed inset-0 bg-black/40' style={{ zIndex: 20 }} />
            <Drawer.Content
              className='bg-zinc-100 flex flex-col rounded-t-[40px] h-[96%] mt-24 fixed bottom-0 left-0 right-0'
              style={{ zIndex: 30 }}
            >
              <div className='p-4 bg-white rounded-t-[40px] flex-1'>
                <div className='mx-auto w-16 h-1.5 flex-shrink-0 rounded-full bg-zinc-300 mb-4' />
                <div className='max-w-md mx-auto mt-7'>
                  <Drawer.Title className='font-semibold mb-4 text-lg'>Pick up Location</Drawer.Title>
                  <div className='flex gap-3 items-center'>
                    <div className='mt-3'>
                      <svg xmlns='http://www.w3.org/2000/svg' width='42' height='46' viewBox='0 0 22 36' fill='none'>
                        <path
                          d='M19.8829 4.15551C22.0212 6.29356 23.2442 9.17942 23.2932 12.2029C23.3422 15.2263 22.2135 18.1503 20.1456 20.3566L19.8829 20.6283L14.392 26.118C13.6952 26.8143 12.7599 27.2197 11.7754 27.2521C10.791 27.2846 9.831 26.9416 9.08996 26.2927L8.9036 26.118L3.41137 20.627C1.22711 18.4428 0 15.4803 0 12.3913C0 9.30226 1.22711 6.33977 3.41137 4.15551C5.59563 1.97125 8.55812 0.744141 11.6471 0.744141C14.7361 0.744141 17.6986 1.97125 19.8829 4.15551ZM11.6471 8.50892C11.1373 8.50892 10.6324 8.60934 10.1614 8.80444C9.69039 8.99955 9.2624 9.28552 8.90189 9.64603C8.54138 10.0065 8.25541 10.4345 8.0603 10.9056C7.8652 11.3766 7.76478 11.8814 7.76478 12.3913C7.76478 12.9011 7.8652 13.406 8.0603 13.877C8.25541 14.348 8.54138 14.776 8.90189 15.1365C9.2624 15.497 9.69039 15.783 10.1614 15.9781C10.6324 16.1732 11.1373 16.2736 11.6471 16.2736C12.6768 16.2736 13.6643 15.8646 14.3924 15.1365C15.1205 14.4084 15.5295 13.4209 15.5295 12.3913C15.5295 11.3616 15.1205 10.3741 14.3924 9.64603C13.6643 8.91795 12.6768 8.50892 11.6471 8.50892Z'
                          fill='#FB8B00'
                        />
                      </svg>
                    </div>
                    <div>
                      <div className='font-semibold text-base'>{boldPart.trim()}</div>
                      <div className='text-sm text-disable'>{restOfAddress.trim()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='p-4 bg-zinc-100 border-t border-zinc-200 mt-auto'>
                <div className='flex gap-6 justify-end max-w-md mx-auto'></div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </div>
    </div>
  )
}

export default MapPinMobileComponent
