import { CommunityType, EnrolledCommunityOutputProps } from 'types/CommunityTypes'
import { httpBearerAuth, processError } from 'utils/requests'
import { ResponseProps } from 'utils/types'

export const getEnrolledCommunities = async (
  communityType?: CommunityType,
): Promise<ResponseProps<{ message: string; communities: EnrolledCommunityOutputProps[] }>> => {
  try {
    const { data } = await httpBearerAuth('get', `/api/enrolledCommunities?communityType=${communityType}`)
    return { data }
  } catch (error: any) {
    return { msg: processError(error), error }
  }
}
