import { Grid, LinearProgress } from '@mui/material'
import InputMobileComponent from 'components/input-mobile'
import MapPinComponent from 'components/map-pin'
import PlacesAutocompleteComponent from 'components/places-autocomplete'
import ToastComponent from 'components/toast'
import WizardComponent from 'components/wizard'
import { useEffect, useState } from 'react'
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom'
import { geocode } from 'utils/map'
import { updateStudentMorningSettings } from 'utils/students'
import { LocationProps, MessageProps, StudentProps, VehicleProps } from 'utils/types'
import { isValidComments } from 'utils/validations'
import UnCheckedIcon from '../../images/afternoon_checkbox.svg'
import CheckedIcon from '../../images/Checkbox.svg'
import './index.css'
import TextAreaComponent from 'components/textarea'

interface MorningComponentProps {
  student: StudentProps
  onUpdate: (cb?: () => void) => void
}

const MorningComponent = (props: MorningComponentProps) => {
  const navigate: NavigateFunction = useNavigate()

  // Inputs: School Name, Location, Student Pick up Location and onUpdate Callbck
  const { student, onUpdate } = props
  const { schoolName, schoolLocation, studentId, pickupLocation } = student
  const { lat, lng } = schoolLocation
  const bounds = lat && lng ? [lng - 0.7, lat - 0.7, lng + 0.7, lat + 0.7] : undefined
  const amDashboardUrl: string = `/students?id=${studentId}`

  // states
  const [studentPickupLocation, setStudentPickupLocation] = useState<LocationProps>({
    address: pickupLocation?.address || '',
  })
  const [pickupVehicle, setPickupVehicle] = useState<VehicleProps>(student?.pickupVehicle ?? 0)
  const [pickupSeatsFilled, setPickupSeatsFilled] = useState<number | null>(student?.pickupSeatsFilled ?? null)
  const [pickupSeatsAvailable, setPickupSeatsAvailable] = useState<number | null>(student?.pickupSeatsAvailable ?? null)
  const [pickupComments, setPickupComments] = useState<string>(student?.pickupComments ?? '')
  const [msg, setMsg] = useState<MessageProps>()
  const [isFullPage, setIsFullPage] = useState(false)
  const [key, setKey] = useState<number>(0)
  const [mapHeight, setMapHeight] = useState(false)
  const [isLoading, setIsLoading] = useState<string>('')

  // handlers
  const onSubmit = async (): Promise<any> => {
    setIsLoading('Saving...')
    let pickupLocation: LocationProps = studentPickupLocation
    if (pickupLocation.address && (!pickupLocation?.lat || !pickupLocation?.lng)) {
      pickupLocation = await geocode(pickupLocation.address)
      if (!pickupLocation?.lat || !pickupLocation?.lng) {
        setMsg({
          style: 'error',
          text: 'We could not locate this address. Verify and re-enter address.',
        })
        setIsLoading('')
        return
      }
    }
    const { msg, error } = await updateStudentMorningSettings(
      schoolName,
      studentId,
      pickupLocation,
      pickupVehicle,
      pickupSeatsFilled,
      pickupSeatsAvailable,
      pickupComments,
    )
    if (error) setMsg(msg)
    else onUpdate(() => navigate(amDashboardUrl))
    setIsLoading('')
  }

  // handlers
  const onCancel = () => navigate(amDashboardUrl)

  const handleToggleFullPage = () => {
    setIsFullPage((prev) => !prev)
    setMapHeight((prev) => !prev)
  }

  const isValid: boolean = isValidComments(pickupComments) && studentPickupLocation.address.trim().length > 0

  return (
    <>
      {/***************** Display progress and error *****************/}
      {isLoading && <LinearProgress />}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}
      {/***************** Display morning settings *****************/}
      <WizardComponent
        onBack={onCancel}
        onCancel={onCancel}
        title={'Morning Carpool'}
        disable={!isValid}
        onClick={onSubmit}
        btnText='Submit'
        data_testId='modal-dialog-action-submit'
      >
        <div className='m-1'>
          <Grid container>
            <Grid item xs>
              <PlacesAutocompleteComponent
                key={key}
                label='Select Address / Landmark'
                defaultValue={studentPickupLocation.address}
                bounds={bounds}
                onChange={(v: string) => setStudentPickupLocation({ address: v })}
                onSubmit={(v: LocationProps) => setStudentPickupLocation(v)}
                onMapClick={handleToggleFullPage}
                dataTestid='pickup-morning-testid'
              />
            </Grid>
          </Grid>
          {/***************** Map *****************/}
          {isFullPage && (
            <MapPinComponent
              currentLocation={pickupLocation?.address ? pickupLocation : schoolLocation}
              mapHeight={mapHeight}
              customHeight='55vh' // height of the map in web
              onSave={(v: LocationProps) => {
                setStudentPickupLocation(v)
                setKey((prevKey) => prevKey + 1) // Changing the key forces a re-render
                handleToggleFullPage()
              }}
            />
          )}
          {/* Select vehicle */}
          <div className='w-full mt-1 mb-3' data-testid='vehicle-testid'>
            <label className='text-gray-400 text-sm font-medium'>Vehicle</label>
            <div className='bg-light-yellow mt-3 mb-3 pl-3 border-l-4 border-custom-yellow mb-1 rounded-tr-xl rounded-br-xl '>
              <div
                className='flex items-center cursor-pointer'
                onClick={() => setPickupVehicle(pickupVehicle ^ VehicleProps.HIRE_TRANSPORT_SERVICE)}
                data-testid='CheckBoxOutlineBlankIcon'
              >
                <img
                  src={pickupVehicle & VehicleProps.HIRE_TRANSPORT_SERVICE ? CheckedIcon : UnCheckedIcon}
                  alt={pickupVehicle & VehicleProps.HIRE_TRANSPORT_SERVICE ? 'Checked' : 'Unchecked'}
                  className='mr-2'
                />
                <span className='text-gray-400 text-sm font-medium py-2'>
                  Are you interested in hiring paid transport service?
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', gap: '16px' }}>
              <InputMobileComponent
                labeltext='Filled Seats'
                type='number'
                max={7} // Maximum value allowed
                min={1} // Minimum value allowed
                value={pickupSeatsFilled !== null ? pickupSeatsFilled : ''}
                onChange={(v: any) => setPickupSeatsFilled(Number(v))}
              />
              <InputMobileComponent
                labeltext='Available Seats'
                type='number'
                max={7} // Maximum value allowed
                min={1} // Minimum value allowed
                value={pickupSeatsAvailable !== null ? pickupSeatsAvailable : ''}
                onChange={(v: any) => setPickupSeatsAvailable(Number(v))}
              />
            </div>
          </div>
          {/* Add comments */}
          <div className='w-full mt-1'>
            <TextAreaComponent
              labeltext='Comments'
              placeholder='Enter details to help with carpooling'
              isRequired={false}
              isError={!isValidComments(pickupComments)}
              value={pickupComments}
              onChange={(v: string) => setPickupComments(v)}
            />
          </div>
        </div>
      </WizardComponent>
    </>
  )
}

interface Props {
  students: StudentProps[]
  processQuery: (id: string) => string
  onUpdate: (cb?: () => void) => void
}

function Index(props: Props): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams()

  // Inputs: Selected Student Id, and Callback
  const { students, processQuery, onUpdate } = props

  // We should never call this component without student id query search parameters, but this is just to be safe
  const studentIdFromQuery = searchParams.get('id') ?? ''
  useEffect(() => {
    setSearchParams({ id: processQuery(studentIdFromQuery) })
  }, [studentIdFromQuery, processQuery, setSearchParams])

  // Build props for child component
  const student: StudentProps | undefined = students?.find((s: StudentProps) => s.studentId === studentIdFromQuery)

  if (!student) return <LinearProgress />
  return <MorningComponent student={student} onUpdate={onUpdate} />
}

export default Index
