import Avatar from '@mui/material/Avatar'
import { useEffect, useState } from 'react'
import './index.css'
interface AvatarComponentProps {
  size?: number
  photoLink?: string
  failedPhotoLinks?: Set<string>
  onFailedPhotoLink?: (url: string) => void
  defaultElement?: JSX.Element
  name?: string
  detailLink?: string
  isNewTab?: boolean
}

const getHslColor = (str: string, s: number = 70, l: number = 50): string => {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Restrict hue to deep yellow tones
  const hue = 45 + (hash % 15) // Narrow range: 45° (rich yellow) to 60° (golden yellow)

  // Fix saturation to maintain vibrancy without neon greens
  const saturation = Math.min(80, Math.max(60, s)) // Vibrant: 60% to 80%

  // Ensure lightness is bold enough for contrast
  const lightness = Math.min(60, Math.max(40, l)) // Balanced: 40% to 60%

  return `hsl(${hue}, ${saturation}%, ${lightness}%)`
}

const getInitials = (fullName: string) => {
  const allNames = fullName.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const AvatarComponent = ({
  size = 10,
  photoLink,
  failedPhotoLinks,
  onFailedPhotoLink,
  defaultElement,
  name,
  detailLink,
  isNewTab,
}: AvatarComponentProps): JSX.Element => {
  const [isValidLogo, setIsValidLogo] = useState<boolean>()

  useEffect(() => {
    setIsValidLogo(photoLink && !failedPhotoLinks?.has(photoLink) ? true : false)
  }, [photoLink, failedPhotoLinks])

  const avatar: JSX.Element = isValidLogo ? (
    <img
      src={photoLink}
      alt='Avatar'
      className={`w-${size} h-${size} rounded-full shadow-sm object-cover`}
      onError={() => {
        setIsValidLogo(false) // Mark as invalid if loading fails
        if (photoLink && onFailedPhotoLink) onFailedPhotoLink(photoLink)
      }}
    />
  ) : (
    (defaultElement ?? (
      <Avatar sx={{ bgcolor: name ? getHslColor(name) : '#d3d3d3' }}>{name ? getInitials(name) : 'N/A'}</Avatar>
    ))
  )

  return detailLink ? (
    <a href={detailLink} target={isNewTab ? '_blank' : '_self'} rel='noopener noreferrer'>
      {avatar}
    </a>
  ) : (
    avatar
  )
}

export default AvatarComponent
