import GridViewIcon from '@mui/icons-material/GridView'
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined'
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import ButtonMenuComponent from 'components/button-menu'
import { useEffect, useRef, useState } from 'react'
import { NavigateFunction, Outlet, useNavigate } from 'react-router-dom'
import { titleCaseSchoolNameForUI } from 'utils/constants'
import Logo from '../../images/cslogo.png'
import './index.css'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const AppBarMenu: React.FC<{
  schoolName: string
  isActive: boolean
  data_testid?: string
}> = ({ schoolName, isActive, data_testid }) => {
  const navigate: NavigateFunction = useNavigate()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens
  const panelRef = useRef<HTMLDivElement>(null)

  // states
  const [isOpen, setIsOpen] = useState(false)

  // handlers
  const handleNavigate = (url: string) => {
    setIsOpen(false)
    navigate(url)
  }

  //hooks
  useEffect(() => {
    if (isOpen) {
      const handleOutsideInteraction = (event: MouseEvent | TouchEvent) => {
        const target = event.target as Node
        if (panelRef.current && !panelRef.current.contains(target)) {
          setIsOpen(false)
        }
      }
      document.addEventListener('mousedown', handleOutsideInteraction)
      document.addEventListener('touchstart', handleOutsideInteraction)
      return () => {
        document.removeEventListener('mousedown', handleOutsideInteraction)
        document.removeEventListener('touchstart', handleOutsideInteraction)
      }
    }
  }, [isOpen, panelRef])

  return (
    <div ref={panelRef} data-testid={data_testid} className='relative'>
      <button className='flex items-center' onClick={isOpen ? () => setIsOpen(false) : () => setIsOpen(true)}>
        <SettingsOutlinedIcon color='info' />
        {!isMobile && <span className='text-headings font-semibold text-sm mx-2.5'>{schoolName}</span>}
      </button>
      {/* App Bar Menu Button */}
      {isOpen && (
        <div className='absolute top-full right-0 mt-2 min-w-[200px] flex flex-col items-start pb-2 bg-white rounded-lg shadow-lg border border-gray-300 z-10'>
          <div className='flex flex-col w-full'>
            {isActive && (
              <>
                <ButtonMenuComponent
                  name='Dashboard'
                  leftIcon={<GridViewIcon color='info' />}
                  onClick={() => handleNavigate('/admin/dashboard')}
                />
                <ButtonMenuComponent
                  name='Families'
                  leftIcon={<PeopleOutlinedIcon color='info' />}
                  onClick={() => handleNavigate('/admin/users')}
                />
              </>
            )}
            <ButtonMenuComponent
              name='Settings'
              leftIcon={<SettingsOutlinedIcon color='info' />}
              onClick={() => handleNavigate('/admin/settings')}
            />
            <hr className='w-full border-t border-gray-300 my-2' />
            <ButtonMenuComponent
              name='Sign out'
              leftIcon={<LogoutOutlinedIcon color='info' />}
              onClick={() => handleNavigate('/admin/signout')}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export const WithAdminNav = ({ isActive, schoolName }: { isActive: boolean; schoolName?: string }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  return (
    <div className='flex'>
      {/* AppBar Section */}
      <AppBar
        position='absolute'
        sx={{ display: 'block', background: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
      >
        <Toolbar>
          {/* Brand Logo */}
          <Typography
            component='a'
            variant='h6'
            href='/admin/settings'
            color='inherit'
            noWrap
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'none', // Disable the cursor
              pointerEvents: 'none', // Disable clicking
            }}
          >
            <img
              src={Logo}
              width='200'
              height='40'
              style={{
                cursor: 'pointer', // Enable the cursor on the image
                pointerEvents: 'auto', // Allow click events on the image
                transition: 'opacity 0.3s', // Smooth transition for hover effect
              }}
              alt='Carpool.School Logo and Brand'
              onMouseOver={(e) => (e.currentTarget.style.opacity = '0.8')} // Change opacity on hover
              onMouseOut={(e) => (e.currentTarget.style.opacity = '1')} // Reset opacity
            />
          </Typography>
          {/* AppBarMenu */}
          {schoolName && (
            <AppBarMenu
              schoolName={titleCaseSchoolNameForUI(schoolName)}
              isActive={isActive}
              data_testid='nav-icon-testid'
            />
          )}
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <Box
          sx={{
            // Provide a top margin to account for appbar height, and nominal bottom margin
            // There is no side panel or drawer on the side. So, provide a nominal padding
            mt: isMobile ? 8 : 9,
            mb: 2,
            px: 2,
            py: 0,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}
