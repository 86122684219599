import { DateTimeProps, PositionProps, ScheduleGroupProps, VehicleProps } from 'utils/types'

/**
 * Represents possible notify types in a single mask
 */
export enum NotifyTypes {
  // Push notifications
  URGENT_REMINDERS = 1 << 0, // Ex: You are being disabled, designated driver etc.
  COMMUNITY_ANNOUNCEMENTS = 1 << 1, // Ex: School admin announcements like bus cancelled etc.
  MARKETING_UPDATES = 1 << 2, // Ex: How are we doing?, New feature etc.
  SMART_REMINDERS = 1 << 3, // Ex: New connections, New school activity added etc.
  GENERAL_UPDATES = 1 << 4, // All other notifications

  // Combined mask for editable notify types
  EDITABLE_NOTIFY_TYPES = (1 << 2) | (1 << 3) | (1 << 4), // MARKETING_UPDATES | SMART_REMINDERS | GENERAL_UPDATES
}

/**
 * User Input Props
 */
export interface UpdateUserInputProps {
  newPhoneNumber?: string
  userFirstName?: string
  userLastName?: string
  userBio?: string
  userSocialLinks?: Record<string, string>
  userNotificationTypesDisabled?: number
  userNotificationDevice?: string
}

export interface UpdateUserNotificationInputProps {
  userNotificationLastRead?: string
}

/**
 * User Output Props
 */
export interface UserOutputProps {
  phoneNumber: string
  name: string
  userFirstName: string
  userLastName: string
  userBio?: string
  userSocialLinks?: Record<string, string>
  userNotificationTypesDisabled: number
  userNotificationDevices?: string[]
  userNotificationLastRead?: string
  userLastSeen?: string
  createdAt: string
  updatedAt: string
}

/**
 * User Notification Output Props
 */
export interface UserNotificationOutputProps {
  notificationType: number
  notificationImageUrl: string
  notificationTitle: string
  notificationBody: string
  notificationActionUrl?: string
  notificationCommunityId?: string
  notificationSentBy: string
  notificationSentAt: string
  notificationIsUnread: boolean
}

/**
 * Connections Output Props
 */
export interface UserPoolOutputProps {
  // School Details
  schoolScheduleGroups: ScheduleGroupProps[]
  // User Profile
  userName: string
  userBio?: string
  userPhotoLink: string
  userSocialLinks?: Record<string, string>
  isUserNotificationDevice: boolean
  userNotificationLastRead?: string
  userLastSeen?: string
  // Student Pool
  studentId: string
  scheduleGroupName: string
  studentActivities?: string[]
  userRelationship: string
  // Pickup Details
  pickupPosition?: PositionProps
  pickupDistance?: number
  pickupVehicle?: VehicleProps
  pickupSeatsFilled?: number
  pickupSeatsAvailable?: number
  pickupComments?: string
  // Dropoff Details
  dropoffPosition?: PositionProps
  dropoffDistance?: number
  dropoffVehicle?: VehicleProps
  dropoffSeatsFilled?: number
  dropoffSeatsAvailable?: number
  dropoffComments?: string
  dropoffTimeDefault?: number
  dropoffTimes?: DateTimeProps
  // Messaging Details
  roomId?: string
}
export interface UsersOutputProps {
  userId: string
  userName: string
  userRelationship: string
  userPhotoLink: string
  userCommunityId: string
  userCommunityStudentId: string
  userCommunityStudentPickupLocation?: PositionProps
  userCommunityStudentDropoffLocation?: PositionProps
}
