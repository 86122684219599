import { IconButton } from '@mui/material'
import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import { styled } from '@mui/material/styles'
import AvatarComponent from 'components/avatar'
import { BackIcon, DeleteGroupIcon, GroupInfoIcon, PrivateGroupIcon, PublicGroupIcon } from 'components/icons'
import ModalConfirmRemoveComponent from 'components/modal-confirm-remove'
import MsgCardComponent from 'components/msg-card'
import RoomMsgTextAreaComponent from 'components/room-msg-textarea'
import ToastComponent from 'components/toast'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { MessageOutputProps } from 'types/MessageTypes'
import { RoomType, RoomUsersOutputProps, UserRoomsOutputProps } from 'types/RoomTypes'
import { createRoomMsg, getRoomMsgs, onCreateMessageSubscription, updateRoomUser } from 'utils/rooms'
import { MessageProps } from 'utils/types'
import './index.css'

const appBarColor = '#ffffff'
const backgroundColor = '#f9f9f9'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

const ChatRoomComponent: React.FC<{
  room: UserRoomsOutputProps
  roomUsers: RoomUsersOutputProps[]
  userId: string
  failedPhotoLinks: Set<string>
  onFailedPhotoLink: (url: string) => void
}> = ({ room, roomUsers, userId, failedPhotoLinks, onFailedPhotoLink }) => {
  const { roomId, roomType } = room
  const [messages, setMessages] = useState<MessageOutputProps[]>([])
  const [nextToken, setNextToken] = useState<string | null>(null)
  const [msg, setMsg] = useState<MessageProps>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const chatContainerRef = useRef<HTMLDivElement | null>(null)

  const lookupUser = roomUsers.reduce<{ [key: string]: Record<string, any> }>(
    (acc, { userId, userName, userPhotoLink }) => {
      acc[userId] = { name: userName, photoLink: userPhotoLink }
      return acc
    },
    {},
  )

  // Canned suggested messages
  const suggestedMessages = ['Carpool?', "I'll drive", 'Omw. ETA:', "When's pickup?", "Who's driving?", 'Ty!']

  // Function to get message count based on screen size
  const getMessageCountForScreen = () => {
    const width = window.innerWidth
    if (width <= 600) return 10
    if (width <= 960) return 15
    return 20
  }

  // Scroll to Bottom
  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTo({ top: chatContainerRef.current.scrollHeight, behavior: 'smooth' })
    }
  }

  // Send Message
  const handleSubmitMessage = async (text: string) => {
    setIsLoading(true)
    const { data, msg, error } = await createRoomMsg(userId, roomId, text)
    if (error || msg) setMsg(msg)
    else if (data) {
      setMessages((prevMessages) => [data.data.createMessage, ...prevMessages]) // Prepend the new message at the beginning
      updateRoomUser(roomId, userId, data.data.createMessage.msgSentAt) // set timestamp of your sent message as last seen msg
    }
    scrollToBottom() // Scroll to bottom after sending a new message
    setIsLoading(false)
  }

  // Initial load - fetch messages and scroll to bottom once
  useEffect(() => {
    const initialLoad = async () => {
      setIsLoading(true)
      const messageCount = getMessageCountForScreen()
      const { data, msg, error } = await getRoomMsgs(userId, roomId, messageCount)
      if (error || msg) setMsg(msg)
      else if (Array.isArray(data?.data.getMessages?.items)) {
        if (data.data.getMessages.items.length) {
          const initialMessages: MessageOutputProps[] = data.data.getMessages.items
          setMessages(initialMessages) // Set initial messages
          setNextToken(data.data.getMessages.nextToken || null) // Update nextToken for further fetches
          updateRoomUser(roomId, userId, initialMessages[0].msgSentAt) // set timestamp of last seen message
        }
      } else setMsg({ style: 'error', text: 'An unexpected error occurred.' })
      scrollToBottom() // Scroll to bottom on initial load
      setIsLoading(false)
    }
    initialLoad()
  }, [roomId, userId])

  // Subscription - fetch new messages, but do not scroll to bottom
  useEffect(() => {
    let unsubscribe: (() => void) | undefined

    const handleNewMessage = (newMessage: MessageOutputProps) => {
      setMessages((prevMessages) => [newMessage, ...prevMessages]) // Prepend the new message at the beginning
      updateRoomUser(roomId, userId, newMessage.msgSentAt) // set timestamp of last seen message
    }
    const setupSubscription = async () => {
      unsubscribe = await onCreateMessageSubscription(roomId, userId, handleNewMessage)
    }

    setupSubscription()

    return () => {
      if (unsubscribe) unsubscribe() // Cleanup WebSocket connection
    }
  }, [roomId, userId])

  // Fetch older messages when scrolling to the top
  const getMessages = useCallback(async (): Promise<void> => {
    if (isLoading || !nextToken) return // Prevent fetch if already loading or no more messages
    setIsLoading(true)
    const messageCount = getMessageCountForScreen()
    const { data, msg, error } = await getRoomMsgs(userId, roomId, messageCount, nextToken)
    if (error || msg) setMsg(msg)
    else if (data) {
      setMessages((prevMessages) => [...prevMessages, ...data.data.getMessages.items]) // Append older messages
      setNextToken(data.data.getMessages.nextToken || null) // Update nextToken
    }
    setIsLoading(false)
  }, [isLoading, nextToken, roomId, userId]) // Include all dependencies used in getMessages

  return (
    <>
      {/***************** Display progress and error *****************/}
      {msg && (
        <ToastComponent style={msg?.style} heading={msg?.heading} text={msg?.text} onClose={() => setMsg(undefined)} />
      )}

      {/***************** Mobile & Web *****************/}
      {/* Scrollable Content */}
      <div
        id='scrollableDiv'
        style={{
          height: 'calc(100vh - 164px - env(safe-area-inset-top, 0px) - env(safe-area-inset-bottom, 0px))',
          overflowY: 'scroll',
          display: 'flex',
          flexDirection: 'column-reverse',
          margin: 'auto',
        }}
        className='bg-body-tertiary p-3'
      >
        <InfiniteScroll
          dataLength={getMessageCountForScreen()}
          next={getMessages}
          hasMore={nextToken === null ? false : true}
          loader={<p className='text-center m-5'>⏳&nbsp;Loading...</p>}
          style={{ display: 'flex', flexDirection: 'column-reverse', overflow: 'visible' }}
          scrollableTarget='scrollableDiv'
          inverse={true}
        >
          {messages.map(({ pk, msgText, msgSentBy, msgSentAt }, idx) => (
            <MsgCardComponent
              key={idx}
              text={msgText}
              sender={lookupUser[msgSentBy]?.name}
              sentAt={msgSentAt}
              photoLink={lookupUser[msgSentBy]?.photoLink}
              failedPhotoLinks={failedPhotoLinks}
              onFailedPhotoLink={onFailedPhotoLink}
              isMyMsg={msgSentBy === userId}
              isPrivateChat={roomType === RoomType.PRIVATE}
            />
          ))}
        </InfiniteScroll>
      </div>
      {/* Fixed Footer with Message Input Area */}
      <div
        style={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: '#f9f9f9', // Match the background color
          padding: '10px',
          paddingBottom: `calc(10px + env(safe-area-inset-bottom, 0px))`, // 10px + safe area padding
          zIndex: 10, // Ensure footer stays above the message list
        }}
      >
        <RoomMsgTextAreaComponent
          onSubmit={handleSubmitMessage}
          suggestedMessages={suggestedMessages}
          placeholder='Message'
        />
      </div>
    </>
  )
}

export const RoomComponent: React.FC<{
  roomId: string
  onRoomDelete: () => Promise<void>
  room: UserRoomsOutputProps
  roomUsers: RoomUsersOutputProps[]
  userId: string
  failedPhotoLinks: Set<string>
  onFailedPhotoLink: (url: string) => void
}> = ({ roomId, onRoomDelete, room, roomUsers, userId, failedPhotoLinks, onFailedPhotoLink }): JSX.Element => {
  const navigate: NavigateFunction = useNavigate()

  return (
    <>
      <AppBar
        position='fixed'
        sx={{
          display: 'block',
          background: appBarColor,
          boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
          paddingTop: 'env(safe-area-inset-top)', // Add safe area padding here
        }}
      >
        <Toolbar>
          <div className={`flex w-full justify-between items-center`}>
            <div className='flex flex-grow items-center gap-2.5 flex-1'>
              <div className='cursor-pointer' onClick={() => navigate(-1)} data-testid='back-fixed-button-testid'>
                <BackIcon />
              </div>
              <div
                className='flex items-center gap-2 cursor-pointer'
                onClick={() => {
                  if (
                    room.roomType === RoomType.PRIVATE &&
                    room.roomCommunityId &&
                    Array.isArray(room.roomMembers) &&
                    room.roomMembers.length === 2
                  ) {
                    const otherMemberPhoneNumber = room.roomMembers?.find((v) => v !== userId)
                    navigate(`/userpool/${room.roomCommunityId}`, {
                      state: { userPhoneNumber: otherMemberPhoneNumber },
                    })
                  } else navigate(`/messaging?action=roomInfo&roomId=${roomId}`)
                }}
              >
                {room.roomType === RoomType.PRIVATE && (
                  <AvatarComponent
                    name={room.roomName}
                    photoLink={room?.roomPhotoLink}
                    failedPhotoLinks={failedPhotoLinks}
                    onFailedPhotoLink={onFailedPhotoLink}
                  />
                )}
                {room.roomType === RoomType.GROUP && <PrivateGroupIcon width={40} height={40} />}
                {(room.roomType === RoomType.PUBLIC || room.roomType === RoomType.ACTIVITY) && (
                  <PublicGroupIcon width={40} height={40} />
                )}
                <span className='text-[color:var(--Color-Tokens-Text-Modes-Base-Second,#68727D)] text-xl font-semibold'>
                  {room.roomName}
                </span>
              </div>
            </div>
            {room.roomType === RoomType.PRIVATE ? (
              <ModalConfirmRemoveComponent
                icon={<DeleteGroupIcon />}
                title='Delete Group'
                body={`All messages will be permanently deleted. Are you sure?`}
                actionText='Delete now'
                onClick={onRoomDelete}
              />
            ) : (
              <IconButton onClick={() => navigate(`/messaging?action=roomInfo&roomId=${roomId}`)}>
                <GroupInfoIcon />
              </IconButton>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          backgroundColor,
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: '64px', // Account for the AppBar height
        }}
      >
        <Box sx={{ mt: 2, mb: 2, px: 0, py: 0 }}>
          <ChatRoomComponent
            room={room}
            roomUsers={roomUsers}
            userId={userId}
            failedPhotoLinks={failedPhotoLinks}
            onFailedPhotoLink={onFailedPhotoLink}
          />
        </Box>
      </Box>
    </>
  )
}

export default RoomComponent
