import MuiAppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { styled, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { Outlet } from 'react-router-dom'
import Logo from '../../images/cslogo.png'
import './index.css'

const AppBar = styled(MuiAppBar)(({ theme }) => ({
  width: '100%',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}))

export const WithNav = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')) // for mobile screens

  return (
    <div className='flex'>
      {/* AppBar Section */}
      <AppBar
        position='absolute'
        sx={{ display: 'block', background: '#ffffff', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)' }}
      >
        <Toolbar>
          {/* Brand Logo */}
          <Typography
            component='a'
            variant='h6'
            href='/'
            color='inherit'
            noWrap
            sx={{
              display: 'flex',
              flexGrow: 1,
              alignItems: 'center',
              textDecoration: 'none',
              cursor: 'none', // Disable the cursor
              pointerEvents: 'none', // Disable clicking
            }}
          >
            <img
              src={Logo}
              width='200'
              height='40'
              style={{
                cursor: 'pointer', // Enable the cursor on the image
                pointerEvents: 'auto', // Allow click events on the image
                transition: 'opacity 0.3s', // Smooth transition for hover effect
              }}
              alt='Carpool.School Logo and Brand'
              onMouseOver={(e) => (e.currentTarget.style.opacity = '0.8')} // Change opacity on hover
              onMouseOut={(e) => (e.currentTarget.style.opacity = '1')} // Reset opacity
            />
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Main Content Section */}
      <Box
        component='main'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
          paddingTop: 'env(safe-area-inset-top)',
          paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <Box
          sx={{
            // Provide a top margin to account for appbar height, and nominal bottom margin
            // There is no side panel or drawer on the side. So, provide a nominal padding
            mt: isMobile ? 8 : 9,
            mb: 2,
            px: 2,
            py: 0,
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </div>
  )
}
