import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Grid from '@mui/material/Grid'
import { PieChart } from '@mui/x-charts/PieChart'
import './index.css'

const PieChartComponent = (props: any) => {
  const { title, data, height } = props
  return (
    <Grid item xs={12} sm={12} md={12} lg={6} xl={6} sx={{ p: 1 }}>
      <Card sx={{ borderRadius: '8px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)' }}>
        <CardContent>
          {title}
          <PieChart
            series={[
              {
                innerRadius: 15,
                paddingAngle: 1,
                cornerRadius: 5,
                data: data.map((item: any, index: number) => ({
                  ...item,
                  color: ['#f0e977', '#f6f2ad', '#c0ba5f', '#908c47', '#605d30'][index % 5], // Assign colors
                  label: `${item.label}: ${item.value}`,
                })),
              },
            ]}
            height={height}
            slotProps={{
              legend: {
                direction: 'column',
                position: { vertical: 'middle', horizontal: 'right' },
                itemMarkWidth: 10,
                itemMarkHeight: 5,
              },
            }}
          />
        </CardContent>
      </Card>
    </Grid>
  )
}

export default PieChartComponent
